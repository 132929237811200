import React, { useEffect } from 'react';
import { getUrlParams } from '../helpers/location';
import useActiveSession from '../hooks/useActiveSession';
import * as Api from '../apis';
import * as liff from '../apis/liff';

const CountPage = ({ location }) => {
  const query = getUrlParams(location.search);
  const { user_question_id = '' } = query;

  useActiveSession(() => {
    Api.postCountData(query.code, user_question_id).then(data => {
      const name = data.data.name;
      liff
        .sendMessages([
          {
            type: 'flex',
            altText: name,
            contents: {
              type: 'bubble',
              body: {
                type: 'box',
                layout: 'vertical',
                contents: [
                  {
                    type: 'text',
                    text: name || '-',
                  },
                ],
              },
            },
          },
        ])
        .then(data => {
          liff.closeWindow();
        })
        .catch(error => {
          console.log(error);
        });
    });
  });

  return <></>;
};

export default CountPage;
